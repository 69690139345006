import Vue from 'vue'
import App from './App.vue'
import VueCookie from 'vue-cookie'
import i18n from '@/plugins/i18n'
import {json2excel, excel2json} from 'js2excel'

//CommonJS

//VUE_ANALYTICS
import VueGtag from 'vue-gtag'

// VUEX - https://vuex.vuejs.org/
import store from './store'

// VUE-ROUTER - https://router.vuejs.org/
import router from './router'

// PLUGINS
import vuetify from './plugins/vuetify'
import './plugins/vue-head' // Not used
import './plugins/vue-gtag' // Not used

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/uppercase'
import './filters/placeholder'
import './filters/trim'
import './filters/formatDate'

// STYLES
// Main Theme SCSS
import './assets/scss/theme.scss'

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false

Vue.use(VueCookie)

Vue.use(VueGtag, {
  config: { id: "G-KSJWBT54TF" }
})

/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/
export default new Vue({
  vuetify,
  router,
  i18n,
  store,
  json2excel,
  excel2json,
  render: (h) => h(App)
}).$mount('#app')
