import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routes = [{
  // Public routes (outside /app)
  path: '/',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',

    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Landing.vue'),
    children: [{ path: '', component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Landing.vue'), alias:['/home']}]
  }]
},

{
  path: '/login',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'login',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Login.vue')
  }]
},
{
  path: '/register',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'register',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Register.vue')
  }]
},
{
  path: '/verify',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'verify',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Verify.vue')
  }]
},
{
  path: '/recover-password',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'recover',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/RecoverPassword.vue')
  }]
},
{
  path: '/set-role',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'set-role',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/SetRole.vue')
  }]
},
{
  path: '/terms-and-conditions',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'terms-and-conditions',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/TermsAndConditions.vue')
  }]
},
{
  path: '/privacy-policy',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/PrivacyPolicy.vue')
  }]
},
// App landing page. The user should be redirected to the people/company/training/ landing pages
{
  path: '/app/people/home',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/PeopleLayout.vue'),
  children: [{
    path: '',
    name: 'app_people_home',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/people/Home.vue')
  }]
},
{
  path: '/app/people/user-profile',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/PeopleLayout.vue'),
  children: [{
    path: '',
    name: 'app',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/people/UserProfile.vue')
  }]
},
{
  path: '/app/people/settings',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/PeopleLayout.vue'),
  children: [{
    path: '',
    name: 'app_people_settings',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/Settings.vue')
  }]
},
{
  path: '/app/people/my-courses',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/PeopleLayout.vue'),
  children: [{
    path: '',
    name: 'app_people_my-courses',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/MyCourses.vue')
  }]
},
{
  path: '/app/people/rate-training-courses',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/PeopleLayout.vue'),
  children: [{
    path: '',
    name: 'app_people_rate-training-courses',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/RateCourses.vue')
  }]
},
{
  path: '/app/people/my-jobs',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/PeopleLayout.vue'),
  children: [{
    path: '',
    name: 'app_people_my-jobs',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/people/MyJobs.vue')
  }]
},
{
  path: '/app/people/learning-paths',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/PeopleLayout.vue'),
  children: [{
    path: '',
    name: 'app_people_learning-paths',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/people/LearningPaths.vue')
  }]
},
{
  path: '/app/people/search-courses',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/PeopleLayout.vue'),
  children: [{
    path: '',
    name: 'app_people_search-courses',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/SearchCourses.vue')
  }]
},
{
  path: '/app/people/course/*',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/PeopleLayout.vue'),
  children: [{
    path: '',
    name: 'app_people_course',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/Course.vue')
  }]
},
  {
    path: '/app/visitor/job/*',
    component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/VisitorLayout.vue'),
    children: [{
      path: '',
      name: 'app_visitor_job',
      component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/visitor/Job.vue')
    }]
  },
  {
    path: '/app/visitor/course/*',
    component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/VisitorLayout.vue'),
    children: [{
      path: '',
      name: 'app_visitor_course',
      component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/visitor/Course.vue')
    }]
  },
{
  path: '/app/people/job/*',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/PeopleLayout.vue'),
  children: [{
    path: '',
    name: 'app_people_job',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/Job.vue')
  }]
},
{
  path: '/app/people/search-jobs',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/PeopleLayout.vue'),
  children: [{
    path: '',
    name: 'app_people_search-jobs',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/SearchJobs.vue')
  }]
},
{
  path: '/app/company/search-jobs',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/CompanyLayout.vue'),
  children: [{
    path: '',
    name: 'app_company_search-jobs',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/SearchJobs.vue')
  }]
},
{
  path: '/app/company/my-candidates',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/CompanyLayout.vue'),
  children: [{
    path: '',
    name: 'app_company_my-candidates',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/company/FavCandidates.vue')
  }]
},
{
  path: '/app/backoffice/home',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/BackOfficeLayout.vue'),
  children: [{
    path: '',
    name: 'app_backoffice_home',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/backoffice/Home.vue')
  }]
},
{
  path: '/app/backoffice/users',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/BackOfficeLayout.vue'),
  children: [{
    path: '',
    name: 'app_backoffice_users',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/backoffice/Users.vue')
  }]
},
{
  path: '/app/backoffice/jobs',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/BackOfficeLayout.vue'),
  children: [{
    path: '',
    name: 'app_backoffice_jobs',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/backoffice/Jobs.vue')
  }]
},
{
  path: '/app/backoffice/courses',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/BackOfficeLayout.vue'),
  children: [{
    path: '',
    name: 'app_backoffice_courses',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/backoffice/Courses.vue')
  }]
},
{
  path: '/app/company/home',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/CompanyLayout.vue'),
  children: [{
    path: '',
    name: 'app_company_home',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/company/Home.vue')
  }]
},
{
  path: '/app/company/user-profile',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/CompanyLayout.vue'),
  children: [{
    path: '',
    name: 'app_company_user-profile',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/company/UserProfile.vue')
  }]
},
{
  path: '/app/company/settings',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/CompanyLayout.vue'),
  children: [{
    path: '',
    name: 'app_company_settings',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/Settings.vue')
  }]
},
{
  path: '/app/company/look-for-candidates',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/CompanyLayout.vue'),
  children: [{
    path: '',
    name: 'app_company_look-for-candidates',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/LookForCandidates.vue')
  }],

},{
    path: '/app/visitor/job/*',
    component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/VisitorLayout.vue'),
    children: [{
      path: '',
      name: 'app_visitor_job_x',
      component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/visitor/Job.vue')
    }]
  },
{
  path: '/app/company/offer-jobs',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/CompanyLayout.vue'),
  children: [{
    path: '',
    name: 'app_company_offer-jobs',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/company/OfferJobs.vue')
  }]
},
{
  path: '/app/company/search-courses',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/CompanyLayout.vue'),
  children: [{
    path: '',
    name: 'app_company_search-courses',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/SearchCourses.vue')
  }]
},
{
  path: '/app/company/rate-training-courses',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/CompanyLayout.vue'),
  children: [{
    path: '',
    name: 'app_company_rate-training-courses',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/RateCourses.vue')
  }]
},
{
  path: '/app/company/my-courses',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/CompanyLayout.vue'),
  children: [{
    path: '',
    name: 'app_company_my-courses',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/MyCourses.vue')
  }]
},
{
  path: '/app/company/course/*',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/CompanyLayout.vue'),
  children: [{
    path: '',
    name: 'app_company_course_x',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/Course.vue')
  }]
},
{
  path: '/app/company/job/*',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/CompanyLayout.vue'),
  children: [{
    path: '',
    name: 'app_company_job_x',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/Job.vue')
  }]
},
{
  path: '/app/training-provider/home',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider_home',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/training-provider/Home.vue')
  }]
},
{
  path: '/app/training-provider/course/*',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider_course_x',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/Course.vue')
  }]
},
{
  path: '/app/training-provider/search-courses',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider_search-courses',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/SearchCourses.vue')
  }]
},
{
  path: '/app/training-provider/my-favourite-courses',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider_my-favourite-courses',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/MyCourses.vue')
  }]
},
{
  path: '/app/training-provider/job/*',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider_job_x',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/Job.vue')
  }]
},
{
  path: '/app/training-provider/my-candidates',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider/my-candidates',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/training-provider/FavCandidates.vue')
  }]
},
{
  path: '/app/training-provider/search-courses',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider/search-courses',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/SearchCourses.vue')
  }]
},
{
  path: '/app/training-provider/search-jobs',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider/search-jobs',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/SearchJobs.vue')
  }]
},
{
  path: '/app/training-provider/user-profile',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider/user-profile',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/training-provider/UserProfile.vue')
  }]
},
{
  path: '/app/training-provider/look-for-candidates',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider_look-for-candidates',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/LookForCandidates.vue')
  }]
},
{
  path: '/app/training-provider/settings',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider_settings',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/Settings.vue')
  }]
},
{
  path: '/app/training-provider/search-people',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider_search-people',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/training-provider/LookingForUser.vue')
  }]
},
{
  path: '/app/training-provider/demanded-skills',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider_demanded-skills',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/training-provider/DemandedSkills.vue')
  }]
},
{
  path: '/app/training-provider/validate-courses',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider_validate-courses',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/training-provider/ValidateCourses.vue')
  }]
},
{
  path: '/app/training-provider/publish-training-courses',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/TrainingProviderLayout.vue'),
  children: [{
    path: '',
    name: 'app_training-provider_publish-training-courses',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/training-provider/PublishTrainingCourses.vue')
  }]
},
{
  path: '/app/about',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'app_about',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/app/common/About.vue')
  }]
},
{
  path: '/auth-error',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '',
    name: 'auth-error',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/error/AuthError.vue')
  }]
},

{
  path: '*',
  component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFound.vue')
  }]
}
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  // scrollBehavior (to, from, savedPosition) {
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
// router.beforeEach((to, from, next) => {
//  return next()
// })

/**
 * After each route update
 */
// router.afterEach((to, from) => {
// })

export default router
